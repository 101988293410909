import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/public/login/login.component';
import { ProvargoComponent } from './components/provargo/provargo-start/provargo.component';
import { WINDOW_PROVIDERS } from './window-token';
import { JwtHelperService, JwtInterceptor, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './components/home/home.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { OpeningHoursComponent } from './components/system/opening-hours/opening-hours.component';
import { CommercialsComponent } from './components/system/commercials/commercials.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { TopNavigationComponent } from './components/system/top-navigation/top-navigation.component';
import { ProvargoFinishedComponent } from './components/provargo/provargo-finished/provargo-finished.component';
import { ProvargoInProgressComponent } from './components/provargo/provargo-in-progress/provargo-in-progress.component';
import { ProvargoArrangementComponent } from './components/provargo/provargo-arrangement/provargo-arrangement.component';
import {ProvargoAdminComponent} from './components/provargo/provargo-admin/provargo-admin.component';
import {QRCodeModule} from 'angularx-qrcode';

export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string): Observable<any> {
      return this.http.get(environment.api + '/translation/translation/3/0000/' + lang);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslationLoader {
  return new TranslationLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProvargoComponent,
    HomeComponent,
    OpeningHoursComponent,
    CommercialsComponent,
    TopNavigationComponent,
    ProvargoFinishedComponent,
    ProvargoInProgressComponent,
    ProvargoArrangementComponent,
    ProvargoAdminComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    QRCodeModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient]
      }
    })
  ],
  providers: [
    WINDOW_PROVIDERS,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const version = 'v1.1.3';
