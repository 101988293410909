import { Component, OnInit } from '@angular/core';
import { CommercialsService } from 'src/app/services/commercials.service';

@Component({
  selector: 'app-commercials',
  templateUrl: './commercials.component.html',
  styleUrls: ['./commercials.component.scss']
})
export class CommercialsComponent implements OnInit {

  constructor(
    public commercialsService: CommercialsService
  ) { }

  ngOnInit(): void {
  }

}
