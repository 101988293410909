<ng-container *ngIf="commercialsService.commercial == true && commercialsService.commercialEmbed != null">
    <div class="vimeo-overlay" (click)="commercialsService.closeCommercial()"></div>
    <div class="vimeo-logo"><img src="/assets/sp-logo-horizontal-white.png"></div>
  
    <div class="vimeo-background"></div>
  
    <div class="vimeo-wrapper">
      <iframe [src]="commercialsService.commercialEmbed" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
    <button class="vimeo-button" (click)="commercialsService.closeCommercial()">Luk</button>
</ng-container>
  