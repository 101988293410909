import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {Receipt} from '../../../modul/receipt';

@Component({
  selector: 'app-provargo-finished',
  templateUrl: './provargo-finished.component.html',
  styleUrls: ['./provargo-finished.component.scss']
})
export class ProvargoFinishedComponent implements OnInit, OnDestroy {

  provargoReceipt: Receipt;
  userName: string = null;
  timer = 20;
  interval;

  constructor(
    public configService: ConfigService
  ) {
  }

  ngOnInit(): void {
    this.userName = this.configService.userName;
    this.provargoReceipt = this.configService.receipt;

    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.finished();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  finished(): void {
    this.configService.currentPage = 'provargo';
  }
}
