<div class="container">
    <main class="py-4"> 
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="card card-default">
                        <div class="card-header">Login</div> 
                        <div class="card-body">
                            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label">E-Mail</label> 
                                    <div class="col-md-12">
                                        <input type="email" formControlName="email" class="form-control">
                                        <small><span *ngIf="loginForm.controls.email.errors">Email is required</span></small>
                                    </div>
                                </div> 
                                
                                <div class="form-group row">
                                    <label class="col-md-12 col-form-label">Password</label> 
                                    <div class="col-md-12">
                                        <input type="password" formControlName="password" class="form-control">
                                        <small><span *ngIf="loginForm.controls.password.errors">Password is required</span></small>
                                    </div> 
                                </div> 
                                
                                <div class="form-group row mb-0">
                                    <div class="col-md-12">
                                        <button [disabled]="!loginForm.valid" type="submit" class="btn btn-primary">Login</button> 
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>