import {Component, OnInit} from '@angular/core';
import {TranslationService} from 'src/app/services/translation.service';
import {CommercialsService} from 'src/app/services/commercials.service';
import {HomeComponent} from '../../home/home.component';
import {ConfigService} from '../../../services/config.service';
import { version } from 'src/app/app.module';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {

  version: string;

  interval;
  intVal = 0;

  constructor(
    public translationService: TranslationService,
    public commercialService: CommercialsService,
    public homeComponent: HomeComponent,
    public configService: ConfigService
  ) {
    this.version = version;
  }

  ngOnInit(): void {
  }

  reload(): void {
    location.reload();
  }

  async refresh(): Promise<void> {
    this.commercialService.closeCommercial();
    this.commercialService.lastInteraction = Date.now();

    console.log('Refresh');
    this.intVal = 1;
    this.interval = setInterval(() => {
      this.intVal--;
      if (this.intVal <= 0) {
        clearInterval(this.interval);
      }
    }, 100);
  }

  clickAdmin(): void {
    console.log('Admin');
    this.configService.currentPage = 'provargo_admin';
  }

}
