export const environment = {
  production: false,
  salt: 'salt',
  api: 'https://stage.api.switchpay.eu/mobilepayapi/api.php/',
  // api: 'http://localhost:8000/mobilepayapi/src/public/api.php/',
  adminSwitchpayId: '1b713643-876c-45ad-beee-2843e70de8a8',
  adminKey: '47aea227-ad6c-42ec-9ca8-c94567f789ca',
  firebase: {
    apiKey: 'AIzaSyDO6PxAIw01xoShXCkeBhKXv98mGVBbXzo',
    authDomain: 'switchpay-181807.firebaseapp.com',
    databaseURL: 'https://switchpay-181807.firebaseio.com',
    projectId: 'switchpay-181807',
    storageBucket: 'switchpay-181807.appspot.com',
    messagingSenderId: '292548876849',
    appId: '1:292548876849:web:b399c3b730dfbac12c9310'
  }
};
