<div class="headline">{{ 'provargo.headline' | translate }}</div>
<div class="main-container" (click)="mainClick()">
  <div class="container-fluid">
    <div class="row">
      <div class="centered_text">{{ 'provargo.products_info' | translate }}</div>
      <div class="products" *ngIf="provargoProducts">
        <div class="product" *ngFor="let provargoProduct of provargoProducts">
          <div>
            <div class="image"><img [src]="provargoProduct.image"></div>
            <div *ngIf="provargoProduct.pricePrAmount && !provargoProduct.pricePrAmountMember" class="price">
              <p class="price_main">{{ 'provargo.price' | translate }} {{ provargoProduct.pricePrAmount }} {{ 'provargo.kr' | translate }}</p>
            </div>
            <div *ngIf="provargoProduct.pricePrAmount && provargoProduct.pricePrAmountMember" class="price">
              <p class="price_main">{{ 'provargo.price' | translate }} {{ provargoProduct.pricePrAmountMember }} {{ 'provargo.kr' | translate }}</p>
              <p class="price_subtext">{{ 'provargo.not_member' | translate }} {{provargoProduct.pricePrAmount}} {{ 'provargo.kr' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="centered_text">{{ 'provargo.scan_qr' | translate }}</div>
    <div class="interaction" *ngIf="homeComponent.qrCode" (click)="test()">
      <div class="code">
        <div class="qr_code">
          <qrcode [qrdata]="'switchpayid=' + homeComponent.qrCode" [width]="700" [margin]="2" [level]="'M'"></qrcode>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showAlert">
  <div class="confirm_background" (click)="closeAlert()"></div>
  <div class="confirm_container qr" (click)="closeAlert()">
    <div class="confirm_box">
      <div class="confirm_headline big">{{ 'provargo.guide_popup_headline' | translate }}</div>
      <div class="confirm_text big">{{ 'provargo.guide_popup_text' | translate }}</div>
      <div class="qr_code center">
        <qrcode [qrdata]="'switchpayid=' + homeComponent.qrCode" [width]="500" [margin]="2" [level]="'M'"></qrcode>
      </div>
      <div class="confirm_buttons">
        <div class="middle full">OK</div>
      </div>
    </div>
  </div>
</ng-container>
