<ng-container *ngIf="openingHoursService.closed == true">
    <div class="closed">
      <div class="closed_container">
        <div class="closed_title" *ngIf="openingHoursService.openingHoursTitle">
          {{ openingHoursService.openingHoursTitle }}
        </div>
        <div class="closed_description" *ngIf="openingHoursService.openAgainDay && openingHoursService.openingHoursDescription">
          <ng-container *ngIf="today">{{ openingHoursService.openingHoursDescription }}<br>kl. {{ openingHoursService.openAgain }}</ng-container>
          <ng-container *ngIf="!today">{{ openingHoursService.openingHoursDescription }} <br>{{ openingHoursService.openAgainDay }} kl. {{ openingHoursService.openAgain }}</ng-container>
        </div>
      </div>
      <div class="closed_logo">
        <img src="assets/sp-logo-horizontal-white.png"/>
      </div>
    </div>
  </ng-container>
