import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Language } from '../entities/language';
import { JWT_NAME } from './authentication.service';
import {ProvargoUserProductModel} from '../modul/provargo-user-product.model';
import {ProvargoControllerModel} from '../modul/provargo-controller.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient) { }

  getLanguages(userId: number): Observable<Language[]> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'translation/languageactivestate/' + userId;
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.get<Language[]>(url, options).subscribe(
         res => observer.next(res),
         err => observer.error(err)
      );
    });
  }

  getProvargoProducts(userId: number): Observable<ProvargoUserProductModel[]> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'provargo/products/' + userId;
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.get<ProvargoUserProductModel[]>(url, options).subscribe(
         res => observer.next(res),
         err => observer.error(err)
      );
    });
  }

  getProvargoControllers(userId: number): Observable<ProvargoControllerModel[]> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'provargo/controllers/' + userId;
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.get<ProvargoControllerModel[]>(url, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  openController(controllerId: number): Observable<any> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'provargo/controllers/open';
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };

      const body = {controllerId};

      this.http.post<any[]>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  closeController(controllerId: number): Observable<any> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'provargo/controllers/close';
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };

      const body = {controllerId};

      this.http.post<any[]>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  startControllerCleaning(controllerId: number): Observable<any> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'provargo/controllers/clean/start';
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };

      const body = {controllerId};

      this.http.post<any[]>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  stopControllerCleaning(controllerId: number): Observable<any> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'provargo/controllers/clean/stop';
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };

      const body = {controllerId};

      this.http.post<any[]>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  removeFirebeaseMessage(id: number): Observable<boolean> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<boolean>(observer => {
      const url = environment.api + 'terminals/firebaseremoveitem/' + id;
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.get<boolean>(url, options).subscribe(
         res => observer.next(res),
         err => observer.error(err)
      );
    });
  }

  addTerminalLog(message: string): Observable<any> {
    return new Observable<any>(observer => {
      const token = localStorage.getItem(JWT_NAME);
      const url = environment.api + 'terminals/logs';
      const body = {
        message
      };
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.post<any>(url, body, options).subscribe(
          res => observer.next(res),
          err => observer.error(err)
      );
    });
  }

  adminLogin(code: string): Observable<any> {
    return new Observable<any>(observer => {
      const token = localStorage.getItem(JWT_NAME);
      const url = environment.api + 'terminals/adminlogin';
      const body = {
        code
      };
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.post<any>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  stopPour(): Observable<any> {
    return new Observable<any>(observer => {
      const token = localStorage.getItem(JWT_NAME);
      const url = environment.api + 'terminals/provargo/stop_pour';
      const body = {};
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.post<any>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  stopPourTimeout(): Observable<any> {
    return new Observable<any>(observer => {
      const token = localStorage.getItem(JWT_NAME);
      const url = environment.api + 'terminals/provargo/stop_pour_timeout';
      const body = {};
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.post<any>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  startTestOrder(): Observable<any> {
    return new Observable<any>(observer => {
      const url = environment.api + 'appintegration/scan/freeintegrator';
      const body = {
        SwitchPayID: environment.adminSwitchpayId,
        AuthUserID: '1',
        AppTransactionID: '1',
        CallbackURL: environment.api + 'appintegration/callback',
        FirstName: 'Fri',
        LastName: 'tapning',
        IsMember: false
      };
      const options = {
        headers: new HttpHeaders({
          Authorization: environment.adminKey,
          'switchpay-checksum-sha256': 'test'
        })
      };
      this.http.post<any>(url, body, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }

  getLastState(): Observable<any> {
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const url = environment.api + 'terminals/provargo/state';
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.get<any>(url, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }
}
