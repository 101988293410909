<div class="headline">
    {{userName || '-'}}
</div>
<div class="main-container">
    <div class="container-fluid">
        <div>
            <div class="information_container">
                <div class="receipt_container">
                    <div class="shaddow"></div>
                    <div class="receipt">
                        <div class="receipt_headline">{{ 'provargo.finished.receipt_headline' | translate }}</div>
                        <hr>
                        <table class="table table-borderless">
                            <tbody *ngIf="provargoReceipt?.items">
                              <tr *ngFor="let provargoReceipt of provargoReceipt.items">
                                <td class="table_left">{{ provargoReceipt.name }}</td>
                                <td>{{ provargoReceipt?.liter }} {{ 'provargo.finished.liter' | translate }}</td>
                                <td>{{ provargoReceipt?.price }} {{ 'provargo.finished.kr' | translate }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <a *ngIf="!provargoReceipt?.items">N/A</a>
                          <div class="receipt_bottom">
                            <hr>
                            <table class="table table-borderless">
                                <tbody>
                                  <tr>
                                    <td class="table_left" *ngIf="provargoReceipt">{{ 'provargo.finished.total' | translate }}</td>
                                    <td>{{ provargoReceipt?.totalLiter || '-' }} {{ 'provargo.finished.liter' | translate }}</td>
                                    <td>{{ provargoReceipt?.totalPrice || '-' }} {{ 'provargo.finished.kr' | translate }}</td>
                                  </tr>
                                </tbody>
                            </table>
                          </div>
                    </div>
                </div>
                <div class="description_container">
                    <div class="description">{{ 'provargo.finished.description' | translate }}</div>
                    <div class="price">{{ provargoReceipt?.totalPrice || '-' }} {{ 'provargo.finished.kr' | translate }}</div>
                    <div class="product">({{ provargoReceipt?.totalLiter || '-' }} {{ 'provargo.finished.liter' | translate }})</div>
                    <div class="button"><div class="interaction_button" (click)="finished()">{{ 'provargo.finished.finished' | translate }} ({{timer}})</div></div>
                </div>
            </div>
        </div>
    </div>
</div>
