import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {BackendService} from '../../../services/backend.service';

@Component({
  selector: 'app-provargo-arrangement',
  templateUrl: './provargo-arrangement.component.html',
  styleUrls: ['./provargo-arrangement.component.scss']
})
export class ProvargoArrangementComponent implements OnInit {

  confirm = false;
  userName: string = null;
  loading = false;

  constructor(
    public configService: ConfigService,
    private backendService: BackendService
  ) { }

  ngOnInit(): void {
    this.userName = this.configService.userName;
  }

  confirmRequired(): void {
    this.confirm = true;
  }

  confirmApproved(): void {
    this.loading = true;
    this.confirm = false;
    this.backendService.stopPour().subscribe(resp => {
      console.log(resp);
    });
  }

  confirmClose(): void {
    this.confirm = false;
  }
}
