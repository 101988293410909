import {Component, OnInit} from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {BackendService} from '../../../services/backend.service';

@Component({
  selector: 'app-provargo-in-progress',
  templateUrl: './provargo-in-progress.component.html',
  styleUrls: ['./provargo-in-progress.component.scss']
})
export class ProvargoInProgressComponent implements OnInit {

  confirm = false;
  userName: string = null;
  loading = false;

  constructor(
    public configService: ConfigService,
    private backendService: BackendService
  ) {
  }


  ngOnInit(): void {
    this.userName = this.configService.userName;
  }

  confirmRequired(): void {
    this.confirm = true;
  }

  confirmApproved(): void {
    this.confirm = false;
    this.loading = true;
    // this.configService.currentPage = 'provargo_arrangement';
    this.backendService.stopPourTimeout().subscribe(resp => {
      console.log(resp);
    });
  }

  confirmClose(): void {
    this.confirm = false;
  }

  finished(): void {
    this.loading = true;
    // this.configService.currentPage = 'provargo_finished';
    this.backendService.stopPour().subscribe(resp => {
      console.log(resp);
    });
  }

}
