import {Component, OnDestroy, OnInit} from '@angular/core';
import { Config } from 'src/app/entities/config';
import { CommercialsService } from 'src/app/services/commercials.service';
import { ConfigService } from 'src/app/services/config.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { OpeningHoursService } from 'src/app/services/openingHours.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  userConfig: Config;
  userId: number;
  retrieveMessagesRunning = false;
  interval;
  qrCode: string = null;
  products: any[] = [];

  constructor(
    public configService: ConfigService,
    private messagingService: MessagingService,
    private commercialsService: CommercialsService,
    private openingHoursService: OpeningHoursService
  ){
      this.getPosConfig();
      this.commercialsService.lastInteraction = Date.now();
  }

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.openingHoursService.checkOpeningHours(this.userConfig);
    }, 6000);

    this.interval = setInterval(() => {
      this.commercialsService.checkLastInteraction(this.userConfig);
    }, 1000);

    this.interval = setInterval(() => {
      this.getPosConfig();
    }, 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  getPosConfig(): void {
    this.userId = Number(localStorage.getItem('user_id'));
    this.configService.getPosConfig(this.userId).subscribe(data => {
      this.userConfig = data;
      console.log(this.userConfig);
      if (this.configService.currentPage == null) { this.configService.currentPage = this.userConfig.home_screen; }
      if (!this.retrieveMessagesRunning) {
        this.messagingService.retrieveMessages(this.userId);
        this.retrieveMessagesRunning = true;
      }
    });

    this.configService.getCode().subscribe(resp => {
      this.qrCode = resp;
    }, err => {
      this.qrCode = null;
      console.log(err);
    });
  }
}
