<ng-container *ngIf="terminalAdmin === null">
  <div class="headline">
    Indtast kode
  </div>
  <div class="main-container">
    <div class="container-fluid">
      <div style="margin-top: 30px;">
        <div class="input-box-row">
          <div class="input-box"><label class="input-box-text">{{getDisplayItem(0)}}</label></div>
          <div class="input-box"><label class="input-box-text">{{getDisplayItem(1)}}</label></div>
          <div class="input-box"><label class="input-box-text">{{getDisplayItem(2)}}</label></div>
          <div class="input-box"><label class="input-box-text">{{getDisplayItem(3)}}</label></div>
          <div class="input-box"><label class="input-box-text">{{getDisplayItem(4)}}</label></div>
          <div class="input-box"><label class="input-box-text">{{getDisplayItem(5)}}</label></div>
        </div>
        <div style="margin-top: 30px;">
          <div class="keypad-row">
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(7)">7
            </button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(8)">8
            </button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(9)">9
            </button>
          </div>
          <div class="keypad-row">
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(4)">4
            </button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(5)">5
            </button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(6)">6
            </button>
          </div>
          <div class="keypad-row">
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(1)">1
            </button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(2)">2
            </button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(3)">3
            </button>
          </div>
          <div class="keypad-row">
            <button [disabled]="display.length == 0 || loading" class="keypad-button" (click)="keypadDelete()"><img
              id="backspace-img" src="assets/backspace-white.svg" alt="R"></button>
            <button [disabled]="display.length == 6 || loading" class="keypad-button" (click)="keypadClick(0)">0
            </button>
            <button [disabled]="display.length == 0 || loading" class="keypad-button" (click)="keypadEnter()">OK
            </button>
          </div>
          <div class="spinner" *ngIf="loading">
            <img src="assets/loading_gear_white.png" width="30%"/>
          </div>
        </div>
      </div>
      <div class="back_button" (click)="goBack()">{{ 'provargo.admin.go_back' | translate }}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showError">
  <div class="confirm_background" (click)="closeError()"></div>
  <div class="confirm_container" (click)="closeError()">
    <div class="confirm_box">
      <div class="confirm_headline error">Ugyldig kode</div>
      <div class="confirm_text">Den indtastede kode er ugyldig</div>
      <div class="confirm_buttons">
        <div class="middle">OK</div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="terminalAdmin !== null">
  <div class="headline">
    Administration
  </div>
  <div class="controller_row">
    <button *ngIf="!loading" (click)="startTestOrder()" class="test_order_button">
      <span class="test_order_button_headline">Start</span>
      <br>
      <span>ordre uden afregning</span>
    </button>
    <button *ngIf="loading" class="test_order_button disabled">
      <span class="test_order_button_headline">Start</span>
      <br>
      <span>ordre uden afregning</span>
    </button>
  </div>
  <div class="main-container">
    <div class="container-fluid">
      <div *ngIf="controllers">
        <div class="controller_row controller_box" *ngFor="let controller of controllers">
          <div>
            <div class="controller_description">
              {{controller.description}}
            </div>
            <div class="controller_indicators">
              Hane status
            </div>
            <div class="row controller_indicators">
              <div class="controller_indicator" *ngFor="let tap of controller.taps">
                <div class="controller_indicator_img">
                  <img *ngIf="tap.lastMl === 0" src="assets/circle_red.svg" alt="">
                  <img *ngIf="tap.lastMl > 0" src="assets/circle.svg" alt="">
                </div>
                <div>
                  {{tap.lastMl}}mL
                </div>
              </div>
            </div>
          </div>
          <!--button (click)="clickStartControllerClean(controller.id)" class="controller_button">Start rens</button>
          <button (click)="clickStopControllerClean(controller.id)" class="controller_button">Stop rens</button-->
        </div>
      </div>
      <div class="spinner_2" *ngIf="loading">
        <img src="assets/loading_gear_white.png" width="30%"/>
      </div>
      <div class="back_button" (click)="goBack()">{{ 'provargo.admin.go_back' | translate }}</div>
    </div>
  </div>
</ng-container>
