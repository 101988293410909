import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CommercialsService {

  commercial:boolean = false;
  commercialEmbed: SafeResourceUrl = null;
  lastInteraction: number;

  constructor(
    private sanitizer: DomSanitizer
  ) {}

  closeCommercial() {
    this.lastInteraction = Date.now();
    this.commercial = false;
    this.commercialEmbed = null;
  }

  openCommercial(embed: string) {
    this.commercialEmbed = this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+ embed +"?background=1&autoplay=1&loop=1&byline=0&title=0&quality=540p");
    this.commercial = true;
  }

  checkLastInteraction(userConfig) {
    if(userConfig) {
      if(userConfig.commercial) {
        if(userConfig.commercials && userConfig.commercialBegin) {
          if(userConfig.commercials.length > 0){
            if(!this.commercial) {
              var dateNow = Date.now();
              var diff = dateNow - this.lastInteraction;
              if(diff > userConfig.commercialBegin) {
                var random = Math.floor(Math.random() * userConfig.commercials.length);
                this.openCommercial(userConfig.commercials[random].embed);
                this.lastInteraction = 0;
              }
            }
          }
        }
      }
    }
  }
}
