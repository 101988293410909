import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/entities/language';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  showLanguages: boolean = true;
  languages: Language[];

  constructor(
    private translate: TranslateService,
    private backendService: BackendService
  ) {}

  getLanguages() {
    const userId = Number(localStorage.getItem('user_id'));
    this.backendService.getLanguages(userId).subscribe(res => {
      this.languages = res;
      var count = 0;
      this.languages.forEach(language => {
        if(language.active) {
          count++;
          this.translate.setDefaultLang(language.code);
        }
      });
      if(count < 2) this.showLanguages = false;
    });
  }

  languageChange(language) {
    this.translate.use(language);    
  }

}
