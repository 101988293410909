<div class="nav">
  <div class="logoswitchpay" (click)="refresh()">
    <img *ngIf="intVal == 0" src="assets/logo.png"/>
    <img *ngIf="intVal > 0" src="../../../assets/circle.svg"
         style="max-width: 10px; margin-bottom: 9px; margin-left: 10px;"/>
  </div>

  <div class="flags" *ngIf="translationService.languages">
    <ng-container *ngFor="let language of translationService.languages">
      <div *ngIf="language.active == 1 && translationService.showLanguages" class="flag"
           (click)="translationService.languageChange(language.code)">
        <img src="../../../assets/flags/{{ language.icon }}" style="width: 75px; height: auto;">
      </div>
    </ng-container>
  </div>

  <div class="admin_button" (click)="clickAdmin()" *ngIf="configService.currentPage && configService.currentPage == 'provargo'">
    <img src="../../../assets/setting-lines.png" style="width: 75px; height: auto;">
  </div>
</div>

<div class="bottom_nav" *ngIf="homeComponent.userConfig">
  <div class="version" (click)="reload()"> {{ version }}
    <span *ngIf="homeComponent.userConfig.user_id">({{ homeComponent.userConfig.user_id }})</span>
  </div>
</div>
