<div class="headline">
  {{userName || '-'}}
</div>

<div class="main-container">
    <div class="container-fluid">
        <div class="row">
            <div class="information_container">
              <div class="top_content">{{ 'provargo.in-progress.hi' | translate }} {{userName || '-'}},</div>
              <div class="top_content">{{ 'provargo.in-progress.title' | translate }}</div>
                <div class="center_content">
                  <div *ngIf="!loading" class="interaction_button" (click)="finished()">{{ 'provargo.in-progress.stop' | translate }}</div>
                  <div *ngIf="loading" class="interaction_button disabled">{{ 'provargo.in-progress.stop' | translate }}</div>
                </div>
                <div class="bottom_content">{{ 'provargo.in-progress.subtitle' | translate }}</div>
            </div>
        </div>
    </div>
    <div *ngIf="!loading" class="start_arrangement" (click)="confirmRequired()">{{ 'provargo.in-progress.start_arrangement' | translate }}</div>
    <div *ngIf="loading" class="start_arrangement disabled">{{ 'provargo.in-progress.start_arrangement' | translate }}</div>
</div>

<div class="spinner" *ngIf="loading">
  <img src="assets/loading_gear_white.png" width="30%"/>
</div>

<ng-container *ngIf="confirm">
    <div class="confirm_background" (click)="confirmClose()"></div>
    <div class="confirm_container">
        <div class="confirm_box">
            <div class="confirm_headline">{{ 'provargo.in-progress.confirm.headline' | translate }}</div>
            <div class="confirm_text">{{ 'provargo.in-progress.confirm.text' | translate }}</div>
            <div class="confirm_buttons"><div class="left" (click)="confirmApproved()">{{ 'provargo.in-progress.confirm.start_arrangement' | translate }}</div><div class="right" (click)="confirmClose()">{{ 'provargo.in-progress.confirm.back' | translate }}</div></div>
        </div>
    </div>
</ng-container>
