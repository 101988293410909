import {Component, OnDestroy, OnInit} from '@angular/core';
import {BackendService} from 'src/app/services/backend.service';
import {HomeComponent} from '../../home/home.component';
import {ConfigService} from '../../../services/config.service';
import {ProvargoControllerModel} from '../../../modul/provargo-controller.model';

@Component({
  selector: 'app-provargo-admin',
  templateUrl: './provargo-admin.component.html',
  styleUrls: ['./provargo-admin.component.scss']
})
export class ProvargoAdminComponent implements OnInit, OnDestroy {

  display = [];
  showError = false;
  terminalAdmin = null;
  controllers: ProvargoControllerModel[] = null;
  loading = false;
  taps = [];
  interval;

  constructor(
    public backendService: BackendService,
    public homeComponent: HomeComponent,
    public configService: ConfigService
  ) {
    this.getControllers();

    this.interval = setInterval(() => {
      this.getControllers();
    }, 5000);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  keypadClick(input: number): void {
    if (this.display.length < 6) {
      this.display.push(input);
    }
  }

  keypadDelete(): void {
    this.display.pop();
    console.log('Delete');
  }

  keypadEnter(): void {
    if (this.display.length === 0) {
      return;
    }

    console.log('Enter');
    let value = '';
    this.display.forEach(char => {
      value += char;
    });
    this.display = [];
    console.log('Code: ' + value);

    this.loading = true;
    this.backendService.adminLogin(value).subscribe(resp => {
      console.log(resp);
      this.terminalAdmin = resp;
      this.loading = false;
    }, err => {
      console.log(err);
      this.showError = true;
      this.loading = false;
    });
  }

  getControllers(): void {
    this.backendService.getProvargoControllers(this.homeComponent.userConfig.user_id).subscribe(resp => {
      console.log(resp);
      this.controllers = resp;
    });
  }

  closeError(): void {
    this.showError = false;
  }

  getDisplayItem(position): string {
    const length = this.display.length;
    if (position === (length - 1)) {
      return this.display[position];
    }
    if (position >= length) {
      return '';
    }

    if (position < (length - 1)) {
      return '*';
    }
  }

  goBack(): void {
    console.log('Admin');
    this.configService.currentPage = 'provargo';
  }

  clickStartControllerClean(controllerId: number): void
  {
    this.backendService.startControllerCleaning(controllerId).subscribe(resp => {
      console.log(resp);
    });
  }

  clickStopControllerClean(controllerId: number): void
  {
    this.backendService.stopControllerCleaning(controllerId).subscribe(resp => {
      console.log(resp);
    });
  }

  startTestOrder(): void
  {
    this.loading = true;
    this.backendService.startTestOrder().subscribe(resp => {
      console.log(resp);
    }, () => {
      this.loading = false;
    });
  }

}
