import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BackendService} from 'src/app/services/backend.service';
import {HomeComponent} from '../../home/home.component';
import {ProvargoUserProductModel} from '../../../modul/provargo-user-product.model';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'app-provargo',
  templateUrl: './provargo.component.html',
  styleUrls: ['./provargo.component.scss']
})

export class ProvargoComponent implements OnInit {

  provargoProducts: ProvargoUserProductModel[];

  showAlert = false;

  constructor(
    public translate: TranslateService,
    public backendService: BackendService,
    public homeComponent: HomeComponent,
    public configService: ConfigService
  ) {
    console.log(homeComponent.userConfig);
    this.provargoProducts = this.homeComponent.products;

    this.backendService.getProvargoProducts(homeComponent.userConfig.user_id).subscribe(res => {
      this.provargoProducts = res;
      this.provargoProducts.forEach(provargoProduct => {
        provargoProduct.pricePrL = provargoProduct.pricePrMl * 1000;
        provargoProduct.pricePrLMember = provargoProduct.pricePrMlMember * 1000;
        provargoProduct.pricePrAmount = Math.round(provargoProduct.pricePrMl * 333);
        provargoProduct.pricePrAmountMember = Math.round(provargoProduct.pricePrMlMember * 333);
      });
      this.homeComponent.products = this.provargoProducts;
    });

    this.backendService.getLastState().subscribe(resp => {
      console.log(resp);
      if (resp.state) {
        const state = resp.state.state;
        const data = resp.state.data;
        if (state !== this.configService.currentPage) {
          this.configService.currentPage = state;
          this.configService.userName = data;
        }
      }
    });
  }

  ngOnInit(): void {
  }

  test(): void {
    console.log('Click test');
    // this.configService.currentPage = 'provargo_in_progress';
  }

  mainClick(): void {
    console.log('Clicked!');
    if (!this.showAlert) {
      this.showAlert = true;
    }
  }

  closeAlert(): void {
    this.showAlert = false;
  }
}
