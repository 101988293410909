<app-top-navigation></app-top-navigation>

<app-commercials></app-commercials>
<app-opening-hours></app-opening-hours>

<div class="main-container">
  <ng-container *ngIf="configService.currentPage">
    <app-provargo *ngIf="configService.currentPage == 'provargo'"></app-provargo>
    <app-provargo-arrangement *ngIf="configService.currentPage == 'provargo_arrangement'"></app-provargo-arrangement>
    <app-provargo-finished *ngIf="configService.currentPage == 'provargo_finished'"></app-provargo-finished>
    <app-provargo-in-progress *ngIf="configService.currentPage == 'provargo_in_progress'"></app-provargo-in-progress>
    <app-provargo-admin *ngIf="configService.currentPage == 'provargo_admin'"></app-provargo-admin>
  </ng-container>
</div>
