import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { isDevMode } from '@angular/core';
import {AppComponent} from '../app.component';
import {version} from '../app.module';

@Injectable({
  providedIn: 'root'
})
export class OpeningHoursService {

  closed = false;
  openAgain: string = null;
  openAgainDay: string = null;
  openingHoursTitle: string = null;
  openingHoursDescription: string = null;
  today = false;
  isDevMode = isDevMode();

  constructor(
    private backendService: BackendService
  ) { }

  getMinutes(str): number {
    const time = str.split(':');
    return time[0] * 60 + time[1] * 1;
  }

  getMinutesNow(): number {
    const timeNow = new Date();
    return timeNow.getHours() * 60 + timeNow.getMinutes();
  }

  addLogItem(message: string): void {
    if (this.isDevMode) { console.log(message); }
    this.backendService.addTerminalLog(message).subscribe();
  }

  checkOpeningHours(userConfig): void {
    if (userConfig && userConfig.opening_hours) {
      this.openingHoursTitle = userConfig.opening_hour.title;
      this.openingHoursDescription = userConfig.opening_hour.description;

      const currentDay = new Date().getDay();
      const result = userConfig.opening_hour.days.find(o => o.day_number === currentDay);

      if (result){
        if (result.all_day_closed) {
          if (!this.closed) { this.addLogItem(`${version}. OPENING: Closing`); }
          this.closed = true;

          let setDay = currentDay + 1;
          let count = 0;
          let dateFound = false;

          while (dateFound === false) {
            if (setDay > 6) { setDay = 0; }
            const nextResult = userConfig.opening_hour.days.find(o => o.day_number === setDay);

            if (nextResult && nextResult.all_day_closed === false) {
              this.openAgain = nextResult.open;
              this.openAgainDay = nextResult.name;
              dateFound = true;
            }
            setDay++;
            count++;
            if (count > 6) { dateFound = true; }
          }
        } else {
          if (result) {
            if (result.close && result.open) {
              const now = this.getMinutesNow();
              let close = this.getMinutes(result.close);
              let open = this.getMinutes(result.open);

              close = close + userConfig.opening_hour.time_buffer;
              open = open - userConfig.opening_hour.time_buffer;

              if (now >= open && now <= close) {
                if (this.closed) { this.addLogItem(`${version}. OPENING: Opening`); }
                this.closed = false;
              } else {
                if (!this.closed) { this.addLogItem(`${version}. OPENING: Closed`); }
                this.closed = true;

                // if open later today
                if (now < open) {
                  this.openAgainDay = result.name;
                  this.openAgain = result.open;
                  this.today = true;
                } else {
                  let setDay = currentDay + 1;
                  let count = 0;
                  let dateFound = false;

                  while (dateFound === false) {
                    if (setDay > 6) { setDay = 0; }
                    const nextResult = userConfig.opening_hour.days.find(o => o.day_number === setDay);

                    if (nextResult && nextResult.all_day_closed === false) {
                      this.openAgain = nextResult.open;
                      this.openAgainDay = nextResult.name;
                      dateFound = true;
                    }
                    setDay++;
                    count++;
                    if (count > 6) { dateFound = true; }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

