import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {BackendService} from 'src/app/services/backend.service';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import Message from 'src/app/modul/message';
import {ConfigService} from './config.service';
import {CommercialsService} from './commercials.service';
import {Observable} from 'rxjs';
import {Receipt} from '../modul/receipt';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messagesRef: AngularFireList<Message>;

  constructor(
    private db: AngularFireDatabase,
    public configService: ConfigService,
    private backendService: BackendService,
    public commercialsService: CommercialsService
  ) {
  }

  retrieveMessages(userId: number): void {
    if (userId) {
      this.commercialsService.closeCommercial();
      this.commercialsService.lastInteraction = Date.now();

      console.log('User message: ' + userId);
      this.messagesRef = this.db.list('/messages', ref => ref.orderByChild('user_id').equalTo(userId));
      this.messagesRef.snapshotChanges().pipe(
        map(changes =>
          changes.map(c =>
            ({key: c.payload.key, ...c.payload.val()})
          )
        )
      ).subscribe(data => {
        console.log('Message data:');
        console.log(data);
        if (data.length > 0) {
          data.forEach(obj => {
            this.handleMessage(obj);
            this.update(obj.messageId).subscribe(() => {}, err => {
              console.log(err);
            });
          });
        }
      });
    }
  }

  handleMessage(data: any): void {
    const message = JSON.parse(data.message);

    if (message.home_screen === '') {
      this.configService.currentPage = 'provargo';
    }

    if (message.provargo_in_progress != null) {
      this.configService.userName = message.provargo_in_progress;
      this.configService.currentPage = 'provargo_in_progress';
    }

    if (message.provargo_finished != null) {
      this.configService.receipt = message.provargo_finished;
      this.configService.receipt.totalLiter = (this.configService.receipt.totalMl / 1000.0).toFixed(2);
      this.configService.receipt.items.forEach(item => {
        item.liter = (item.tappedAmount / 1000).toFixed(2);
      });
      this.configService.currentPage = 'provargo_finished';
    }

    if (message.provargo_arrangement != null) {
      if (message.provargo_arrangement !== '') {
        this.configService.userName = message.provargo_arrangement;
      }
      this.configService.currentPage = 'provargo_arrangement';
    }

    if (message.refresh) {
      console.log('Refresh!');
      location.reload();
    }

    console.log(message);
  }

  update(id: number): Observable<boolean> {
    return this.backendService.removeFirebeaseMessage(id);
  }
}
