import { Component, OnInit } from '@angular/core';
import { OpeningHoursService } from 'src/app/services/openingHours.service';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.scss']
})
export class OpeningHoursComponent implements OnInit {

  constructor(
    public openingHoursService: OpeningHoursService
  ) { }

  ngOnInit(): void {
  }

}
