import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from '../entities/config';
import {ProvargoTapModel} from '../modul/provargo-tap.model';
import {Receipt} from '../modul/receipt';
import {JWT_NAME} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  currentPage: string = null;
  userName: string = null;
  receipt: Receipt = null;
  constructor(private http: HttpClient) { }

  getPosConfig(userId: number): Observable<Config> {
    const url = environment.api + 'posconfig/' + userId;
    return this.http.get<Config>(url);
  }

  getCode(): Observable<any> {
    const url = environment.api + 'terminals/appintegration/code';
    const token = localStorage.getItem(JWT_NAME);
    return new Observable<any>(observer => {
      const options = {
        headers: new HttpHeaders({
          Authorization: token
        })
      };
      this.http.get<any[]>(url, options).subscribe(
        res => observer.next(res),
        err => observer.error(err)
      );
    });
  }
}
