import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';

export interface LoginForm {
  email: string;
  password: string;
}

export const JWT_NAME = 'blog-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  login(loginForm: LoginForm) {
    return this.http.post<any>(environment.api + 'login/terminaltoken', {email: loginForm.email, password: loginForm.password}).pipe(
      map((token) => {
        localStorage.setItem('user_id', token.user.id);
        localStorage.setItem(JWT_NAME, token.token);
        return token;
      })
    );
  }

  logout(): void {
    localStorage.removeItem(JWT_NAME);
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem(JWT_NAME);
    if (token != null) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }
}
