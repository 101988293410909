<div class="headline">
    {{userName || '-'}}
</div>

<div class="main-container">
    <div class="container-fluid">
        <div class="row">
            <div class="information_container">
                <div class="top_content">{{ 'provargo.arrangement.title' | translate }}</div>
                <div class="center_content"><div class="interaction_button">{{ 'provargo.arrangement.stop' | translate }}</div></div>
                <div class="bottom_content">{{ 'provargo.arrangement.subtitle' | translate }}</div>
            </div>
        </div>
    </div>
    <div *ngIf="!loading" class="end_arrangement" (click)="confirmRequired()">{{ 'provargo.arrangement.end_arrangement' | translate }}</div>
    <div *ngIf="loading" class="end_arrangement disabled" (click)="confirmRequired()">{{ 'provargo.arrangement.end_arrangement' | translate }}</div>
</div>

<div class="spinner" *ngIf="loading">
  <img src="assets/loading_gear_white.png" width="30%"/>
</div>

<ng-container *ngIf="confirm">
    <div class="confirm_background" (click)="confirmClose()"></div>
    <div class="confirm_container">
        <div class="confirm_box">
            <div class="confirm_headline">{{ 'provargo.arrangement.confirm.headline' | translate }}</div>
            <div class="confirm_text">{{ 'provargo.arrangement.confirm.text' | translate }}</div>
            <div class="confirm_buttons"><div class="left" (click)="confirmApproved()">{{ 'provargo.arrangement.confirm.end_arrangement' | translate }}</div><div class="right" (click)="confirmClose()">{{ 'provargo.arrangement.confirm.back' | translate }}</div></div>
        </div>
    </div>
</ng-container>
